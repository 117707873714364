export default function transformToOption(options, fieldValue = "id", fieldLabel = "name"){
  if (Array.isArray(options)) {
    return options.map(item => ({
      ...item,
      employeeId: item.employeeId || item.id,
      MailNickname: item[fieldValue],
      value: item[fieldValue],
      label: item[fieldLabel]
    }))
  }
  
  return [{ value: options[fieldValue], label: options[fieldLabel]}]
}