import { apiFetch } from '../configuration/api/apiFetch';
import { API_URLS } from '../configuration/api/apiConstants';

export const authSSO = (params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.AUTH,
    params,
    headerParams: false,
    api: process.env.REACT_APP_AUTH_API
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response)
    })
  })
  return dataFetch
}

export const getMaintenance = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.MAINTENANCE,
    originApp: true,
    api: process.env.REACT_APP_AUTH_API
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response.maintenance)
    })
  })
  return dataFetch
}