import { useState } from "react"
import Select from "./Select"
import RequiredField from "./RequiredField"
import { useTranslation } from "react-i18next"
import { Controller } from "react-hook-form";
import { getGlobalUsers } from '../../services/newActionServices';
import transformToOption from '../../utils/transformToOptionSelect';

export default function SearchUser({ setData, control, name, required = true }) {
  const [t] = useTranslation("global")
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  const searchUser = (newValue) => {
    if(newValue.length > 3){
      goToSearchGlobalUser(newValue)
    }
  }

  const goToSearchGlobalUser = (mail) => {
    setLoading(true)
    getGlobalUsers({ 
      mail: mail, 
      expand: "id,mail,displayName,employeeId,mailNickName", 
      filterUsersWithoutEmployeeId: true, 
      filterUsersWithoutMail: true,
      filterUsersWithoutMailNickName: true,
      filterUsersWithoutOfficeLocation: false,
    }).then(users => {
      setUsers(transformToOption(users, "mailNickname", "mail"))
    }).finally(() => setLoading(false))
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required }}
      render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
        <Select
          formField={{
            className: "col-12",
            shouldValidate: error !== undefined,
            validation: {
              id: "error-msg-responsible",
              msg: t("validation.required"),
              type: "error"
            }
          }}
          label={required ? <RequiredField text={t(`new-action.${name}`)} /> : t(`new-action.${name}`)}
          onChange={val => {
            setData(val)
            onChange(val.value)
          }}
          onInputChange={searchUser}
          isLoading={loading}
          /* value={value} */
          inputRef={ref}
          options={users}
          isSearchable={true}
          id={name}
        />
      )}
    />
  )
}

