import { apiFetch } from '../configuration/api/apiFetch';
import { API_URLS } from '../configuration/api/apiConstants';

export const getFlowDetails = (params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.FLOW_DETAILS,
    params
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response)
    })
  })
  return dataFetch
}

export const getGlobalUsers = (params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.GLOBAL_USERS,
    api: process.env.REACT_APP_AUTH_API,
    originApp: true,
    params
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response)
    })
  })
  return dataFetch
}

export const actionRegister = (data, successMsg) => {
  const fetchSettings = {
    method: "POST",
    url: API_URLS.ACTION_REGISTER,
    data,
    successMsg
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response) resolve()
    })
  })
  return dataFetch
}

export const getActions = (params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.ACTIONS,
    params
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response)
    })
  })
  return dataFetch
}

export const getFlowsHead = (params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.FLOWS_HEAD,
    params
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response)
    })
  })
  return dataFetch
}