export const API_URLS = {
  AUTH: 'authSSO',
  PERIODS: "periods",
  HFBS: "hfbs",
  PA: "pa",
  STORES: "stores",
  FLOWS: "flows",
  MAIN: "main",
  FLOW: (id) => `flow_${id}`,
  FLOW_DETAILS: "flows_detail",
  GLOBAL_USERS: "users",
  ACTION_REGISTER: "actionsregister",
  DEPARTMENTS: "departments",
  CATEGORY_PRODUCTIVITY: "category-productivity",
  DATE_FLOW7: "flow_7/availability",
  ACTIONS: "actions",
  FLOWS_HEAD: "flows_head",
  ACTIONS_REGISTER: "actionsregister",
  INFO: "screeninfo",
  MAINTENANCE: "application/check-maintenance"
};