/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateActualDate, updateFilterPa } from "../store/globalSlice";
import Load from "../components/global/Load";
import { useNavigate } from "react-router-dom";
import { actionRegister} from "../services/newActionServices";
import { PRODUCTIVITY_ID } from "../utils/flowsIds";
import GeneralNewAction from "../components/global/GeneralNewAction";
import ProductivityNewAction from "../components/global/ProductivityNewAction";

export default function NewAction() {
  const [t] = useTranslation("global")
  const dispatch = useDispatch();
  const flow = useSelector((state) => state.global.flowSelected)
  const filters = useSelector((state) => state.global.filtersSelected)
  const user = useSelector((state) => state.global.user)
  const [loading, setLoading] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  
  const navigate = useNavigate()

  useEffect(()=>{
    if(filters && !loadingButton){
      //if the sesion is too long in days update actual week when open this page
      dispatch(updateActualDate())
      //Clear default pa filter isn`t it null
      if(filters.pa !== null) dispatch(updateFilterPa(null))
    }
  },[filters])


  const saveAction = (data)=>{
    setLoadingButton(true)
    actionRegister(data, t('new-action.success')).then(() => {
      navigate('/dashboard')
    })
  }

  useEffect(()=>{
    if(flow && filters && user) setLoading(false)
  },[flow,filters, user])

  return (
    <>
      {loading && 
        <Load />
      }
      {flow.id !== PRODUCTIVITY_ID && !loading &&
        <GeneralNewAction 
          saveAction={(data) => saveAction(data)}
          flow={flow}
          store={filters.store}
          hfb={filters.hfb}
          user={user}
          loadingButton={loadingButton}
        />
      }
      {flow.id === PRODUCTIVITY_ID && !loading &&
        <ProductivityNewAction 
          saveAction={(data) => saveAction(data)}
          flow={flow}
          store={filters.store}
          departmentSelected={filters.department}
          categorySelected={filters.categoryProductivity}
          user={user}
          loadingButton={loadingButton}
        />
      }
    </>
  )
}
